const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
const compareDate = new Date(process?.env?.COMPARE_DATE);

export const hostEventUrl = process?.env.HOST_EVENT_URL || "https://dev-events.konfhub.com";
export const certhubUrl = process?.env.CERTHUB_URL || "https://dev-cert.konfhub.com";
export const quizhubUrl = process?.env.QUIZHUB_URL || "https://dev-quiz.konfhub.com";
export const baseUrl = process?.env.BASE_URL || "https://dev.konfhub.com";
export const loginUrl = process?.env.LOGIN_URL || "https://dev-accounts.konfhub.com/login";
export const gMapApiKey = process?.env.MAP_API_KEY || "AIzaSyBkCKa3Rg5BeGbk2uxvOVFEbLcxNulxLOc";
export const GoogleAnalyticsId = process?.env.GOOGLE_ANALYTICS_ID || "G-02TDXKQYLG";
export const ClarityId = process?.env.CLARITY_ID || "hez36l9h0e";
export const s3Url = `https://konfhub-image-files-${process?.env.ENV_NAME || "dev"}.s3.${process?.env.AWS_PROJECT_REGION || "ap-southeast-1"}.amazonaws.com`;
export const mediaUrl = `https://${process?.env.ENV_NAME === "prod" ? "media.konfhub.com" : "dev-media.konfhub.com"}`;
export const xApiKey = process?.env.CAPTURE_X_API_KEY
	? process?.env.CAPTURE_X_API_KEY
	: `d2252ec3-bf55-4d62-bf6e-d9dff52b6172`;
export const publicStripePublishableKey =
	process?.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ||
	"pk_test_51Nx4nbSBwIcmEW9pktp33m6yaXqdSgxS8H4XBAG2CKxWE3FteP6oaLWyKDKCAxE6JgYYiE4TzW7b2Hdr5MC81mUb00W21j4XfU";
export const facebook_id = process?.env.FACEBOOK_APP_ID;

export const razorpayKey =
	currentDate - compareDate < 0
		? process?.env.RAZOR_PAY_KEY || "rzp_test_ZMcokv2rt3vDAB"
		: process?.env.RAZOR_PAYX_KEY || "rzp_test_uv3UwYoeD6zPql";
