import React, { useState, useEffect } from 'react';
import { errorToaster, successToaster } from '../../helpers/utils';
import { subscribeUser, getUserById } from '../../crud/users.crud';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as workerTimers from 'worker-timers'
import dynamic from "next/dynamic";

const Modal = dynamic(() => import('react-modal'), {
    ssr: false,
  });

const customNewsletterModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: "32px",
        maxWidth: "474px",
        minheight: "32px",
        maxheight: "80vh",
        padding: 0,
        borderRadius: '15px',
        border: 'none',
        width: '85%'
    },
    overlay: {
        overflowX: "scroll"
    },
};

const SubscribeNewsletterModal = (props) => {
    const [loading, setLoading] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(true);
    const [userEmail, setUserEmail] = useState("");

    const clientType = 1;
    const subscriptionSource = props.route === "/" ? 1 : props.route === "/resources" ? 5 : props.route === "/communities" ? 4 : 1;

    useEffect(() => {
        const isSubscribedBool = localStorage.getItem("isSubscribed");
        if (isSubscribedBool !== "false") {
            if (props.signedIn === true) {
                getProfile();
            } else if (props.signedIn === false && (props.route === "/" || props.route === "/resources" || props.route === "/communities")) {
                setTimeout(() => {
                    setIsSubscribed(false)
                }, 60000);
            }
        }
    }, [props.signedIn])

    useEffect(() => {
        if (isSubscribed === false) {
            localStorage.setItem("isSubscribed", false)
        }
    }, [isSubscribed])


    const getProfile = async () => {
        try {
            const res = await getUserById();
            if (res.data.email_subscription) setIsSubscribed(true)
            if (res.data.email_subscription == false && (props.route === "/" || props.route === "/resources" || props.route === "/communities")) {
                setTimeout(() => {
                    setIsSubscribed(false)
                }, 60000)
                setUserEmail(res.data.email_id)
                // return () => {
                //     clearInterval(interval);
                // }
            }
        } catch (err) {
            console.log(err)
            setIsSubscribed(false)
        }
    }



    let formik = useFormik({
        enableReinitialize: true,
        initialValues: { email_id: userEmail },
        validationSchema: Yup.object({ email_id: Yup.string().required('Email is required').email("Please enter a valid email") }),
        onSubmit: async (values) => {
            try {
                let res = await subscribeUser(values.email_id, clientType, subscriptionSource)
                if (res.status === 200)
                    successToaster("Subscribed Successfully")
                    if (props.signedIn === true) {
                        getProfile();
                    } 
                setLoading(false);
                setIsSubscribed(true)
            } catch (err) {
                console.log(err)
                errorToaster("Something went wrong, could not subscribe user")
                setLoading(false);
                setIsSubscribed(false);
            }
        }
    });

    return (
        <>
        {!isSubscribed && (
            <Modal
                isOpen={!isSubscribed}
                onRequestClose={() => setIsSubscribed(true)}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                style={customNewsletterModalStyles}
                contentLabel="Subscribe Modal"
            >
                <div>
                    <img src="/img/newsletter.svg" className="newsletter" />
                    <img src="/img/closeIcon.svg" className="close-icon" onClick={() => setIsSubscribed(true)} />
                </div>
                <div className="newsletter-content">
                    <div className="heading">
                        Subscribe to our newsletter!
                    </div>
                    <div className="sub-heading">
                        No Spam - We Promise! <br /> You can unsubscribe at anytime ✌️
                    </div>
                    <div className="email-div">
                        <input
                            className="email-input"
                            placeholder="Enter your email address"
                            value={formik.values.email_id}
                            onChange={(e) => formik.setFieldValue("email_id", e.target.value)}
                        />
                        <button className="send-btn"
                            onClick={() => formik.submitForm()}
                            disabled={formik.errors?.email_id}
                        >
                            <img src="/img/Send.svg" className="" />
                        </button>
                    </div>
                    {((formik.errors?.email_id)) && <span className="error-text">{formik.errors.email_id}</span>}
                </div>
            </Modal>
        )}

            <style jsx>{` 
                .newsletter{
                    background-color: black;
                    width: 100%;
                    max-width: 474px;
                    height: auto;
                }
                .newsletter-content{
                    margin: 25px 0 40px 0;
                    text-align: center;
                    position: relative;
                }
                .close-icon{
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    cursor: pointer;
                }
                .heading{
                    font-family: 'Caveat Brush';
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 38px;
                    color: #FB5850;
                    padding: 0 10px;
                }
                .sub-heading{
                    margin-top: 15px;
                    font-family: 'Prompt';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #572148;
                    padding: 0 10px;
                }
                .email-div{
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;    
                    position: relative;
                }
                .email-input{
                    border: none;
                    background: rgba(0, 0, 0, 0.05);
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    padding: 10px;
                    width: 70%;
                    outline: none;
                    margin-right: 44px;
                }
                .send-btn{
                    border: none;
                    background: #FB5850;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    padding: 7.7px;
                    position: absolute;
                    bottom: 0;
                    right: 48px;
                }
                .error-text {
                    margin-left: 5px;
                    font-size: 14px;
                    color: red;
                  }

                  @media (max-width: 420px) {
                    .email-input {
                        width: 60%;
                        padding-right: 20px;
                    }
                  }
            `}</style>
        </>
    )
}

export default React.memo(SubscribeNewsletterModal);
