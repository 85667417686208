import React from "react";
import App from "next/app";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./../src/config/aws.exports";
import Router, { withRouter } from "next/router";
import AuthContext from "./../src/contexts/AuthContext";
import AuthChecker from "./../src/common/AuthChecker";
import RedirectChecker from "./../src/common/RedirectChecker";
// import Modal from "react-modal";
// Modal.setAppElement("#__next");
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/lib/ReactCrop.scss";
import "tippy.js/dist/tippy.css";

import CookiePopup from "../src/components/CookiePopup";
import Cookies from "js-cookie";
import { setJwtToken } from "../src/crud/axios";
import * as workerTimers from "worker-timers";

import Head from "next/head";
import { DefaultSeo } from "next-seo";
import { defaultSeo } from "../src/config/next-seo.config";

import "./../public/css/App.css";
import "./../public/css/Styles.css";

import Script from "next/script";
import SubscribeNewsletterModal from "../src/pages/Newsletter/SubscribeNewsletterModal";

// import 'bootstrap/dist/css/bootstrap.min.css'
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

Amplify.configure({
	...awsconfig,
	ssr: true
});

class MyApp extends App {
	constructor(props) {
		super(props);
		this.state = {
			user: this.props.user || { attributes: {} },
			signedIn: this.props.signedIn !== undefined ? this.props.signedIn : null,
			overlay: true,
			refreshToken: null,
			hasCookiesAccepted: Cookies.get("Cookie") !== undefined
		};
	}

	async componentDidMount() {
		const queryParams = this.props.router.query;
		Router.events.on("routeChangeComplete", this.authAndRedirectCheck);
		let { user, signedIn } = await AuthChecker(null, true);
		if (!signedIn && Cookies.get("authToken") !== undefined) {
			Cookies.remove("authToken");
			this.props.router.replace("/", undefined, { shallow: false });
		} else if (!signedIn) {
			RedirectChecker(this.state.signedIn, {
				pathname: this.props.router.pathname,
				asPath: this.props.router.asPath
			});
		} else {
			if (signedIn) setJwtToken(user.signInUserSession.idToken.jwtToken);
			this.refreshToken();
		}

		//If utm parameters are present set them in localstorage (Removed after registration)
		if (queryParams.utm_campaign)
			localStorage.setItem("utm_campaign", queryParams.utm_campaign);
		if (queryParams.utm_medium) localStorage.setItem("utm_medium", queryParams.utm_medium);
		if (queryParams.utm_source) localStorage.setItem("utm_source", queryParams.utm_source);
		this.setState({ ...this.state, user, signedIn });
	}

	componentWillUnmount() {
		Router.events.off("routeChangeComplete", this.authAndRedirectCheck);
	}

	/*UNSAFE_componentWillReceiveProps(props) {
		if (props.signedIn !== undefined && props.user !== undefined) {
			if (this.state.signedIn !== props.signedIn) {
				this.setState({
					...this.state,
					user: props.user,
					signedIn: props.signedIn,
				});
			}
		}
	}*/

	refreshToken = async () => {
		let refreshToken = workerTimers.setInterval(
			async () => {
				try {
					const user = await Auth.currentAuthenticatedUser();
					const session = await Auth.currentSession();
					user.refreshSession(session.refreshToken, (err, session) => {
						const { idToken } = session;
						setJwtToken(idToken.jwtToken);
					});
				} catch (err) {
					clearInterval(refreshToken);
					// console.log("cs -- error log -- ", err);
					window.location.href = "/logout";
				}
			},
			60 * 60 * 1000
		);
		this.setState({ ...this.state, refreshToken });
	};

	authAndRedirectCheck = async () => {
		let authData = await AuthChecker(null);
		RedirectChecker(authData.signedIn, {
			pathname: this.props.router.pathname,
			asPath: this.props.router.asPath
		});
	};

	setAuth = (user = {}, signedIn = true) => {
		clearInterval(this.state.refreshToken);
		if (signedIn) {
			this.refreshToken();
		}
		this.setState({ ...this.state, user, signedIn });
	};

	hideOverlayLoading = () => {
		if (
			typeof document !== "undefined" &&
			document.getElementsByClassName("overlay-loader")[0] !== undefined
		) {
			document.getElementsByClassName("overlay-loader")[0].classList.remove("d-block");
			document.getElementsByClassName("overlay-loader")[0].classList.add("d-none");
		}
	};
	acceptCookie = () => {
		this.setState({ ...this.state, hasCookiesAccepted: true });
	};

	render() {
		const { Component, pageProps, err } = this.props;
		const { user, signedIn, hasCookiesAccepted } = this.state;
		pageProps.signedIn = signedIn;

		return (
			<>
				<DefaultSeo {...defaultSeo} />
				<Head>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="/favicon/apple-touch-icon.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="32x32"
						href="/favicon/favicon-32x32.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="16x16"
						href="/favicon/favicon-16x16.png"
					/>
					<link rel="manifest" href="/favicon/site.webmanifest" />
					<link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
					<link rel="preconnect" href="https://www.google-analytics.com" />
					<link rel="preconnect" href="https://ssl.google-analytics.com" />
					<link rel="preconnect" href="https://www.googletagmanager.com" />

					{/* <Script
						id="ms_clarity"
						strategy="afterInteractive"
						dangerouslySetInnerHTML={{
							__html: `
							(function(c,l,a,r,i,t,y){
						c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
						t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
						y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
						})(window, document, "clarity", "script", "fkqnen70dz");`,
						}}
					/> */}
				</Head>
				<Script
					src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js"
					integrity="sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW"
					crossOrigin="anonymous"
				/>
				<AuthContext.Provider
					value={{
						user,
						signedIn,
						setAuth: this.setAuth,
						hasCookiesAccepted,
						acceptCookie: this.acceptCookie
					}}
				>
					{/* {signedIn === null ? (
						<div className="vh-100 d-flex" >
							<Loading className={'m-auto'} color="#fb5850" />
						</div>
					) : ( */}
					<>
						<Component {...pageProps} err={err} />
						{this.hideOverlayLoading()}
					</>
					{/* )} */}
					<CookiePopup route={this.props.router.route} />
					<SubscribeNewsletterModal route={this.props.router.route} signedIn={signedIn} />
				</AuthContext.Provider>
			</>
		);
	}
}

/*MyApp.getInitialProps = async ({ctx}) => {
	let authData = await AuthChecker(ctx);
	if(ctx.req && ctx.req.headers.cookie !== undefined) {
		if(ctx.req.headers.cookie.includes('Cookie=Accept')) authData.cookieVal = true;
	} else {
		if(Cookies.get('Cookie') === 'Accept') authData.cookieVal = true;
	}
	return authData;
}*/

export default withRouter(MyApp);
