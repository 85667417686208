import React, { Component } from "react";
import Cookies from "js-cookie";
import AuthContext from "../contexts/AuthContext";
// import { Button, Modal } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { initGA } from "../helpers/utils";
import { ClarityId, GoogleAnalyticsId } from "../config/config";
import dynamic from "next/dynamic";
import { clarity } from "react-microsoft-clarity";
const Switch = dynamic(() => import('react-switch'), { ssr: false });
const Modal = dynamic(() => import('react-bootstrap').then((mod) => mod.Modal), { ssr: false });
const Header = dynamic(() => import('react-bootstrap').then((mod) => mod.Modal.Header), { ssr: false });
const Title = dynamic(() => import('react-bootstrap').then((mod) => mod.Modal.Title), { ssr: false });
const Body = dynamic(() => import('react-bootstrap').then((mod) => mod.Modal.Body), { ssr: false });
const Footer = dynamic(() => import('react-bootstrap').then((mod) => mod.Modal.Footer), { ssr: false });

class CookiePopup extends Component {
	static contextType = AuthContext;
	constructor(props) {
		super(props);
		this.state = {
			cookieVal: this.props.cookieVal !== undefined ? this.props.cookieVal : false,
			showCookieModal: false,
			analyticsCookies: true,
			isWidget: (this.props.route === "/widget/[eventUrl]" || this.props.route === "/widget/single/[eventUrl]") ? true : false,
			isEventOrCheckout: (this.props.route === "/[eventUrl]" || this.props.route === "/checkout/[eventUrl]" || this.props.route === "/ticket-confirmation") ? true : false, // to add analytics cookie and not show popup
			showCookieBox: false
		}
	}

	componentDidMount() {
		if (typeof window !== 'undefined') {
			if (Cookies.get('Cookie') !== undefined) this.setState({ ...this.state, cookieVal: true });
			if (Cookies.get('AnalyticsCookies') === "Accept") this.handleAcceptCookie();
			setTimeout(() => { this.setState({ ...this.state, showCookieBox: true }) }, 2000)
			/* if user has never accepted cookie and is going to event / checkout page, accept cookies but add
			another cookie to make sure the cookies gets deleted when the user visits any other page */
			if (Cookies.get('Cookie') === undefined && Cookies.get('AnalyticsCookies') === undefined &&
				(this.props.route === "/[eventUrl]" || this.props.route === "/checkout/[eventUrl]" || this.props.route === "/ticket-confirmation")) {
				Cookies.set("ShouldDeleteCookie", "true", { expires: 800, sameSite: 'strict' })
				this.handleAcceptCookie();
			} else if (Cookies.get("ShouldDeleteCookie") === "true") {
				this.handleDeclineCookie();
			}
		}
	}

	handleAcceptCookie = () => {
		initGA(GoogleAnalyticsId);
		clarity.init(ClarityId);
		clarity.consent();
	};

	handleDeclineCookie = () => {
		//remove google analytics cookies
		// Remove the default tracker.
		if (window.ga) window.ga('remove');
		// Remove the default cookies
		// _ga is used to distinguish users.
		Cookies.remove('_ga', { path: '/', domain: document.domain });
		// _gid is used to distinguish users.
		Cookies.remove('_gid', { path: '/', domain: document.domain });
		// _gat is used to throttle request rate.
		Cookies.remove('_gat', { path: '/', domain: document.domain });;
		if(Cookies.get("ShouldDeleteCookie") === "true") {
			Cookies.remove('ShouldDeleteCookie', { path: '/', domain: document.domain });;
		}
	};

	render() {
		// for mobile responsive
		const screenWidth = typeof window !== 'undefined' ? window.innerWidth : null;
		const isMobile = screenWidth < 575;
		const cookieSettingsText = isMobile ? "Settings" : "Cookie Settings";
		const allowAllCookiesText = isMobile ? "Accept All" : "Allow All Cookies";

		return (
			<>
				{
					!this.state.cookieVal && this.state.showCookieModal === false && !this.state.essentialCookies && !this.state.isWidget && !this.state.isEventOrCheckout && this.state.showCookieBox ?
						(<div id="cookie-popup-container" className="cookie-container cookie-not-con p-4">
							<div className="cookie-not-in">
								<div className="row">
									<div className="col-sm-7 col-md-7 col-12 mb-3 mb-sm-0">
										<div className="heading">About Cookies on this site</div>
										<p className="d-inline-block mt-2 sub-heading">
											We use cookies to collect and analyse information on site performance and usage,
											to provide social media features and to enhance and customise content and your user experience.
											<>&nbsp;<a href="/cookie-policy" target="_blank" rel="noreferrer" className="pointer fw-bold">Learn More</a></>
										</p>
									</div>
									<div className="col-sm-5 col-md-4 col-12">
										<div className="justify-content-evenly text-center d-flex align-items-center ">
											<p className="app-menu-item cookie-setting mx-lg-3 px-3 mx-2 py-2 hover-class cookies-button"
												onClick={() => this.setState({ ...this.state, showCookieModal: true })}
											>
												{cookieSettingsText}
											</p>


											<button
												id="cookie-popup-btn"
												className="btn btn-theme cookies-button"
												onClick={() => {
													Cookies.set("Cookie", "Accept", { expires: 800, sameSite: 'strict' });
													Cookies.set("AnalyticsCookies", this.state.analyticsCookies === true ? "Accept" : "Declined", { expires: 800, sameSite: 'strict' });
													this.handleAcceptCookie()
													this.setState({ ...this.state, cookieVal: true })
													this.setState({ ...this.state, essentialCookies: true })
													this.context.acceptCookie();
												}}
											>
												{allowAllCookiesText}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>)
						: ''
				}
				{this.state.showCookieModal && <Modal
					show={this.state.showCookieModal}
					animation={true}
					onHide={() => {
						this.setState({ ...this.state, showCookieModal: false })
					}}
					aria-labelledby="contained-modal-title-vcenter"
					centered
					size="lg"
					className="px-4"
				>
						<Header className="border-0" closeButton>
							<Title id="contained-modal-title-vcenter">
								Advanced Cookie Settings
							</Title>
						</Header>
						<Body>
							<div className="row">
								<div className="col-sm-10">
									<div className="heading">
										Essential Cookies
									</div>
									<div className="mt-2 subText">
										These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled.
									</div>
								</div>
								<div className="col-sm-2">
									<div className="d-flex justify-content-center align-items-center w-100 h-100">
										<Tippy
											content={`Essential cookies can’t be disabled`}
											placement="top"
											arrow={true}
											className="custom-tippy"
										>
											<span>
												<Switch
													name="essential_cookies"
													height={18}
													width={35}
													offColor="#DDDDDD"
													onColor="#FB5850"
													handleDiameter={13}
													uncheckedIcon={false}
													checkedIcon={false}
													checked={true}
													onChange={() => {
													}}
													disabled={true}
												/>
											</span>
										</Tippy>
									</div>
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-sm-10">
									<div className="heading">
										Enable Analytics Cookies
									</div>
									<div className="mt-2 subText">
										These cookies help us to understand how visitors interact with our website, discover errors and provide a better overall analytics.
									</div>
								</div>
								<div className="col-sm-2">
									<div className="d-flex justify-content-center align-items-center w-100 h-100">
										<div>
											<Switch
												name="analytics_cookies"
												height={18}
												width={35}
												offColor="#DDDDDD"
												onColor="#FB5850"
												handleDiameter={13}
												uncheckedIcon={false}
												checkedIcon={false}
												checked={this.state.analyticsCookies}
												onChange={() => {
													this.setState({ ...this.state, analyticsCookies: !this.state.analyticsCookies })
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</Body>
						<Footer>
							<button className="btn btn-theme" variant="primary" onClick={(e) => {
								Cookies.set("AnalyticsCookies", this.state.analyticsCookies === true ? "Accept" : "Declined", { expires: 800, sameSite: 'strict' });
								Cookies.set("Cookie", "Accept", { expires: 800, sameSite: 'strict' });
								this.state.analyticsCookies === true ? this.handleAcceptCookie() : this.handleDeclineCookie();
								this.context.acceptCookie();
								this.setState({ ...this.state, cookieVal: true })
								this.setState({ ...this.state, essentialCookies: true })
								this.setState({ ...this.state, showCookieModal: false })
							}}>
								Save
							</button>
						</Footer>
				</Modal>}
				<style jsx global>
					{`
						.cookie-not-con {
							animation: slideUp 0.3s forwards;
						  -webkit-animation: slideUp 0.3s forwards;
						}
						.heading {
							font-size:18px;
							font-weight: 800;
							font-family:"Nunito";
							color: #572148;
						}
						.sub-heading{
							font-size:16px;
							color: #572148;
						}	
						.subText {
							font-size: 13px;
						}
						.app-menu-item {
							font-weight: normal;
							font-size: 18px;
							line-height: 27px;
							color:${'#572148'};
							text-decoration: none;
							cursor: pointer;
						}
						.cookie-setting {
							font-size: 16px;
							line-height: 24px;
							color: #fb5850;
							border: 2px solid #fb5850;
							text-decoration: none;
						}
						.hover-class {
							transition: 0.3s all;
						}
						.hover-class:hover {
							color: #F9f0e6 !important;
						}
						.modal-header,
						.modal-body,
						.modal-footer {
							padding: 1.5rem 2rem 1.5rem 2rem; //change the padding as you want
						}
						@media only screen and (max-width: 575px){
						.cookie-container{
							padding:1rem !important;
						}	
						.heading{
							font-size:16px;
							line-height:16px;
							color: #572148;
							font-weight:800;
						}	
						.sub-heading{
							font-size:14px;
							line-height:19px;
							color: #572148;
							
						}	
						.cookies-button{
							font-size:15px !important;
							line-height:24px !important;
							padding-left:16px !important;
							padding-right:16px !important;
							padding-top:6px !important;
							padding-bottom:6px !important;
							width:100%;
						}

						}
					`}
				</style>
			</>
		)
	}
}

export default React.memo(CookiePopup);
