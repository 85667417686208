import { baseUrl, facebook_id } from "./config"

const defaultTitle = "KonfHub: Event Management, Registration & Ticketing Platform"
const defaultDescription = "Unlock seamless event experiences with KonfHub! Simplify registration, streamline ticketing, and master event management effortlessly. Join us now!"

export const defaultSeo = {
    title: defaultTitle,
    titleTemplate: "%s",
    description: defaultDescription,
    canonical: `${baseUrl}/`,
    openGraph: {
        url: `${baseUrl}/`,
        title: defaultTitle,
        description: defaultDescription,
        site_name: 'KonfHub',
        type: 'website', // Adding og:type
        images: [
            {
                url: 'https://media.konfhub.com/kh-link-preview.png',
                width: 800,
                height: 600,
                alt: 'KonfHub',
                type: 'image/png',
            },
        ],
        fb_app_id: `${facebook_id}`, // Adding fb:app_id
    },
     twitter: {
         handle: '@KonfHub',
         site: '@KonfHub',
         cardType: 'summary_large_image',
     }
}

export const seoFunct = (pageSeo) => {
    const seoItems = {
        homeSeo: {
            title: defaultTitle,
            description: defaultDescription,
            canonicalRoute: ""
        },
        resourcesSeo: {
            title: "Access high quality free tech learning resources | KonfHub",
            description: "Get free access to high quality tech learning resources from exclusive software events hosted by KonfHub.",
            canonicalRoute: "/resources"
        },
        pricingSeo: {
            title: "Create Free or Paid Events, Webinars, Meetups, Conferences | KonfHub",
            description: "Host free or paid events - online, hybrid or in-person events, conferences, meetups or workshops. Contact us for customized plans.",
            canonicalRoute: "/pricing"
        },
        logosSeo: {
            title: "Brand Gallery | KonfHub logos for partner events & conferences",
            description: "Download the logo kit for online, hybrid and in-person event branding in partnership with KonfHub.",
            canonicalRoute: "/logos"
        },
        awardsSeo: {
            title: "KonfHub Awards & Recognition",
            description: "Our features help you drive the right audience & make your event more successful.",
            canonicalRoute: "/awards"
        },
        CodeofConductSeo: {
            title: "Code of Conduct for online events, quizzes and tournaments",
            description: "All attendees, sponsors, speakers, and volunteers are required to follow the non-negotiable KonfHub code of conduct.",
            canonicalRoute: "/code-of-conduct"
        },
        CookiePolicySeo: {
            title: "Cookie Policy",
            description: "Read more about how Konfhub uses cookies and similar technologies in the course of our business, through our website KonfHub.",
            canonicalRoute: "/cookie-policy"
        },
        TermsAndConditionsSeo: {
            title: "Terms & Conditions for online events, quizzes and tournaments",
            description: "View the terms and conditions on which KonfHub provides the services to the user through the website.",
            canonicalRoute: "/terms-and-conditions"
        },
        PrivacyPolicySeo: {
            title: "Privacy Policy for online events, quizzes and tournaments",
            description: "Read more about how we, at KonfHub, collect, handle and process data of our customers and visitors.",
            canonicalRoute: "/privacy-policy"
        },
        ContactUsSeo: {
            title: "Leading Online Event Platform in India | Get in touch | KonfHub",
            description: "Get in touch to discover the features of the free event platform for online, hybrid or in-person events. Book a free demo.",
            canonicalRoute: "/contact-us"
        },
        FeaturesSeo: {
            title: "Event Management Solutions Platform Features | KonfHub",
            description: "Robust features for event organizers and attendees. Discover seamless event management, comprehensive promotion tools, and a user-friendly experience",
            canonicalRoute: "/features"
        },
        CheckInAppSeo: {
            title: "Simplifying Event Registrations & Management.",
            description: "KonfHub CheckinApp simplifies event check-ins, managing entries, tracking participation, and ensuring seamless integration with KonfHub's platform. Enhance event flow and elevate attendee engagement effortlessly.",
            canonicalRoute: "/checkinapp"
        },
        SponsorAppSeo: {
            title: "Simplifying Event Registrations & Management.",
            description: "KonfHub's Sponsor App offers sponsors a seamless and user-friendly solution to capture leads efficiently through virtual or physical booths. This intuitive platform equips sponsors with tools to interact with event attendees, gather leads, and effectively manage their engagement throughout the event.",
            canonicalRoute: "/sponsorapp"
        },
        WhiteLabelingSeo: {
            title: "Introducing KonfHub's White Label Feature: Customize Your Events with Ease!",
            description: "Experience the power of customization with KonfHub's white label feature. Tailor your event branding to perfection, ensuring a seamless and personalized experience for your attendees. Stand out from the crowd and make your event truly yours with our intuitive customization options.",
            canonicalRoute: "/whitelabeling"
        },
        DevelopersSeo: {
            title: "Access KonfHub's Developer Section for Your Events!",
            description: "Experience the full potential of KonfHub's developer tools for your event website! Effortlessly incorporate event registrations directly onto your site using our Registration API, Registration Button, or Ticketing Widget. Select the option that aligns perfectly with your requirements, whether it's for free events or paid ones.",
            canonicalRoute: "/developers"
        },
        PaymentsSettlementsSeo: {
            title: "Streamlining Financial Operations: Explore Konfhub's Payments & Settlements Hub",
            description: "This page serves as a hub for managing payments, tracking transactions, and facilitating settlements for various services and transactions within the platform. Users can access features such as payment history, invoice generation, payment methods, and settlement options, ensuring transparency and efficiency in financial processes.",
            canonicalRoute: "/payments-settlements"
        },
        TicketingSeo: {
            title: "Effortless Event Attendance: KonfHub Introduces Seamless Ticketing Experience",
            description: "KonfHub's comprehensive ticketing feature streamlines event attendance with customizable ticket types and seamless payment processing. Attendees can easily purchase tickets, manage their registrations, and receive instant confirmations. Organizers gain full control over ticket sales, attendee data, and analytics through a user-friendly dashboard. With secure ticketing infrastructure, KonfHub ensures a smooth and hassle-free event experience for both organizers and attendees.",
            canonicalRoute: "/ticketing"
        },
        StripeConnectSeo: {
            title: "Simplify Event Payments Globally with KonfHub's Seamless Stripe Integration",
            description: "KonfHub streamlines payment processing for international events, offering a seamless solution for organizers. Through Stripe, event hosts can accept payments from attendees globally, accommodating various currencies and guaranteeing secure transactions. Whether it's ticket sales or registration fees, Stripe facilitates secure and convenient payment management, simplifying the organization of events.",
            canonicalRoute: "/stripe-connect"
        },
        TapPaymentsSeo: {
            title: "Streamlined Global Payments",
            description: "KonfHub now supports Tap Payments, facilitating smooth international transactions and expanding payment options for event organizers and participants worldwide.",
            canonicalRoute: "/tap-payments"
        },
        useCasesSeo: {
            title: "Empowering Event Organizer with Versatile Use Cases",
            description: "Discover how Konfhub's event management platform can streamline your event planning process. Konfhub has all the features needed for successful event",
            canonicalRoute: "/usecases"
        },
        communitiesSeo: {
            title: "Easy Event Hosting Platform for Organizers",
            description: "Take the stress out of event planning with easy-to-use event hosting platform. From small gatherings to large conferences, access all the tools you need for seamless organization",
            canonicalRoute: "/communities"
        },
        pitchgroundSeo: {
            title: "Event Management, Registration & Ticketing Platform - KonfHub",
            description: "With KonfHub, creating your tech event and making it successful is as simple as One.. Two.. Three! One: Create and go live within minutes. Two: Gamify for audience engagement. Three: Amplify through contests &amp; multiple channels.",
            canonicalRoute: "/pitchground"
        },
    }
    return {
        title: seoItems[pageSeo].title,
        titleTemplate: "%s",
        description: seoItems[pageSeo].description,
        canonical: `${baseUrl}/${seoItems[pageSeo].canonicalRoute}`,
        openGraph: {
            url: `${baseUrl}/${seoItems[pageSeo].canonicalRoute}`,
            title: seoItems[pageSeo].title,
            description: seoItems[pageSeo].description,
            site_name: 'KonfHub',
            images: [
            {
                url: 'https://media.konfhub.com/kh-link-preview.png',
                width: 800,
                height: 600,
                alt: 'KonfHub',
                type: 'image/jpeg',
            },
        ],
        },
         twitter: {
             handle: '@KonfHub',
             site: '@KonfHub',
             cardType: 'summary_large_image',
        }
    }
}