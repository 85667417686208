import Router from "next/router";
import isRelativeUrl from 'is-relative-url';
import Routes from './../routes/routes';

const RedirectChecker = async (signedIn, ctx) => {
	let { pathname, asPath, res } = ctx;
	//if(res) pathname = asPath = resolvedUrl;
	if (signedIn) {
		if (asPath.match(new RegExp('redirect=', 'gi'))) {
			asPath = asPath.split('redirect=')[1].replace(/\/\//g, '');
			if(!isRelativeUrl(asPath)) asPath = "/";
			if (res) {
				res.writeHead(302, { Location: asPath });
				res.end();
			} else {
				Router.push(asPath, undefined, { shallow: false });
			}
			return true;
		} else {
			if (Routes.unAuthenticatedRoutes.includes(pathname) && !Routes.both.includes(pathname) && !asPath.match(new RegExp('redirect=', 'gi'))) {
				if (res) {
					res.writeHead(302, { Location: "/" });
					res.end();
				} else {
					Router.push("/", undefined, { shallow: false });
				}
			}
			return true;
		}
	} else {
		if (pathname === '/logout') {
			if (res) {
				res.writeHead(302, { Location: "/" });
				res.end();
			} else {
				Router.push("/", undefined, { shallow: false });
			}
			return true;
		} else {
			if ((!Routes.unAuthenticatedRoutes.includes(pathname) && !Routes.both.includes(pathname)) || Routes.authenticatedRoutes.includes(pathname)) {
				if (res) {
					res.writeHead(302, { Location: `/?redirect=${asPath}` });
					res.end();
				} else {
					Router.push(`/?redirect=${asPath}`, undefined, { shallow: false });
				}
				return true;
			}
		}
	}
	return false;
}

export default RedirectChecker;
