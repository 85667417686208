import moment from "moment-timezone";
import GetCurrency from "./services";
import { CurrencyWithAmount } from "./utils";
import _ from "lodash";

export const refreshPage = () => {
	window.location.reload(false);
};

function shuffle(array) {
	var currentIndex = array.length,
		randomIndex;
	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
}

export const hostedConfList = array => {
	const duplicationArray = shuffle(array);
	const copy_duplicateArray = Array.from(duplicationArray);
	const temp = [];
	temp.push(...copy_duplicateArray.splice(0, 1));
	temp.push(...copy_duplicateArray.splice(5, 1));
	return duplicationArray.concat(temp);
};

export const featuredEventSpace = length => {
	if (length > 3) {
		return (Math.floor(length / 4) + 1) * 275;
	} else return 250;
};

export const capitalizeFirstLetter = string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export function capitalizeWords(text) {
	// Split the text into words
	var words = text.split(" ");

	// Capitalize the first letter of each word
	var capitalizedWords = words.map(function (word) {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});

	// Join the words back together
	var capitalizedText = capitalizedWords.join(" ");

	return capitalizedText;
}

export const titleCase = str => {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
};

// get youtube image thumbnail
export const get_youtube_thumbnail = (
	url,
	backgroundUrl = "https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-04-19T04%3A54%3A26.365Z"
) => {
	if (url) {
		var video_id, thumbnail, result;
		if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
			video_id = result.pop();
		} else if ((result = url.match(/youtu.be\/(.{11})/))) {
			video_id = result.pop();
		}

		if (video_id) {
			var thumbnail = `https://img.youtube.com/vi/${video_id}/hqdefault.jpg`;
			return thumbnail;
		}
	}
	return backgroundUrl;
};

//convert html to plain text and take only 165 characters
export const convertToPlainSeo = html => {
	return `${html.replace(/<[^>]+>/g, "").substring(0, 160)}...`;
};

// Returns date and time from utc to IST
export const getISTDateNTime = dateTime => {
	let tempData;
	tempData = moment.utc(dateTime).toDate();
	tempData = moment(tempData).format("YYYY-MM-DD HH:mm:ss");

	return tempData;
};

// Returns converted date & time based on timezone
export const convertTimezone = (dateTime, timezone, optional) => {
	let convertedDateTime;
	convertedDateTime = moment.utc(dateTime).tz(timezone);

	if (optional == "ticket") {
		return moment(convertedDateTime).format("YYYY-MM-DD HH:mm").replace(/ /g, "T");
	} else return convertedDateTime;
};

// Returns date and time from utc to IST
export const getDateFromTimeStamp = dateTime => {
	let tempData;
	tempData = moment(dateTime).format("YYYY-MM-DD");
	return tempData;
};

export const userBookingStatuses = {
	0: { status: "", color: "" },
	1: { status: "Lead", color: "#0CAF30" },
	2: { status: "Active", color: "#0CAF30" },
	3: { status: "Cancelled", color: "#FB5850" },
	4: { status: "", color: "" },
	5: { status: "", color: "" },
	6: { status: "Waitlist", color: "#0CAF30" },
	7: { status: "Waitlist_Approved", color: "#0CAF30" },
	8: { status: "Waitlist_Rejected", color: "#FB5850" }
};

// To figure if it is a light or a dark color
export const fontColorFunction = (hex, setColor) => {
	const colorCode = hex.replace("#", "");
	const r = Number.parseInt(colorCode.substr(0, 2), 16);
	const g = Number.parseInt(colorCode.substr(2, 2), 16);
	const b = Number.parseInt(colorCode.substr(4, 2), 16);
	const a = Number.parseInt(colorCode.substr(6, 2) || "FF", 16);

	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
	return setColor != "opposite"
		? brightness > 125
			? `rgba(51, 51, 51, ${a / 255})`
			: `rgba(255, 255, 255, ${a / 255})`
		: brightness < 125
			? `rgba(51, 51, 51, ${a / 255})`
			: `rgba(255, 255, 255, ${a / 255})`;
};

// event url regex validation
export const eventUrlValidation = value => {
	return value.match(/^[a-z0-9-]+$/);
};

// filters two object, and return the objects that have the same idd
export function filterObjectsById(array1, array2) {
	// Convert array2 into a Set for faster lookup
	const idSet = new Set(array2.map(obj => obj.ticket_id));

	// Filter objects from array1 whose id is present in array2
	const filteredArray = array1.filter(obj => idSet.has(obj.ticket_id));

	return filteredArray;
}

export function replaceAll(string, search, replace) {
	// replaceAll alternative as replaceAll doesnt work while deploying
	return string.split(search).join(replace);
}

//Group ticket discount time
export function isOnDiscount(val) {
	const todaysTime = moment().valueOf();
	let discountStartTime;
	let discountEndTime;
	val &&
		val.some(({ start_timestamp, end_timestamp }) => {
			discountStartTime = moment.utc(start_timestamp).local().valueOf();
			discountEndTime = moment.utc(end_timestamp).local().valueOf();
		});
	return todaysTime >= discountStartTime && todaysTime <= discountEndTime;
}
export function hasGroupDiscountOffer(groupDiscounts, ticketId) {
	if (_.isEmpty(groupDiscounts)) return false;

	const all_ticket_ids_with_group_discount = [];
	for (const groupDiscountObj of groupDiscounts) {
		all_ticket_ids_with_group_discount.push(...groupDiscountObj.attached_ticket_ids);
	}

	if (all_ticket_ids_with_group_discount.includes(ticketId)) return true;

	return false;
}

export function getGroupDiscountDetails(discounts, tickets, couponCode) {
	// Initialize variables to keep track of the maximum discount and its code
	if (_.isEmpty(discounts) || _.isEmpty(tickets) || couponCode !== "")
		return { maxDiscount: 0, maxDiscountCode: "" };

	// Initialize variables to keep track of the maximum discount and its code
	let maxDiscount = 0;
	let maxDiscountCode = "";
	let discountAmount = 0;

	// Iterate through each discount in the discounts array
	for (const discount of discounts) {
		// Check if the discount is a group discount and is enabled
		if (discount.is_group_discount && discount.is_enabled) {
			// Initialize variables to keep track of the total number of selected tickets
			// and the applicable total ticket price for this discount
			let totalSelectedTickets = 0;
			let applicableTicketPrice = 0;

			// Iterate through each ticket in the tickets array
			for (const ticket of tickets) {
				// Check if the current ticket's ID is included in the discount's attached_ticket_ids
				if (discount.attached_ticket_ids.includes(ticket.ticket_id)) {
					// Add the number of selected tickets to the total
					totalSelectedTickets += ticket.totalSelected;
					// Add the total price for the selected tickets to the applicable ticket price
					applicableTicketPrice += ticket.ticket_price * ticket.totalSelected;
				}
			}

			// Check if the total number of selected tickets within the discount's attached_ticket_ids is within the min and max limits
			if (
				totalSelectedTickets >= discount.minimum_tickets &&
				totalSelectedTickets <= discount.maximum_tickets
			) {
				// Calculate the discount amount based on the discount type
				if (discount.coupon_type === 2) {
					// For percentage-based discounts (coupon_type 2)
					discountAmount += (applicableTicketPrice * discount.discount) / 100;
				} else if (discount.coupon_type === 1) {
					// For fixed amount discounts (coupon_type 1), scale the discount with the number of selected tickets
					// Ensure the discount does not exceed the total price
					// TODO: check this logic
					discountAmount += Math.min(
						discount.discount * totalSelectedTickets,
						applicableTicketPrice
					);
				}

				// Update maxDiscount and maxDiscountCode if the current discount amount is greater
				if (discountAmount > maxDiscount) {
					maxDiscount = discountAmount;
					maxDiscountCode = discount.coupon_code;
				}
			}
		}
	}

	// Return an object containing the maximum discount amount and the corresponding discount code
	return { maxDiscount, maxDiscountCode };
}

//Group ticket discount conditions text
export function discountContentByCondition(val, data = false) {
	if ((data && val.attached_ticket_ids.includes(data.ticket_id)) || data === false) {
		if (val.minimum_tickets == 0 && val.coupon_type == 1) {
			return (
				<>
					You avail minimum of{" "}
					<CurrencyWithAmount
						fontSize={"20px"}
						currencyName={val?.currency_name}
						number={val?.discount}
					/>{" "}
					discount for purchasing upto {val.maximum_tickets} tickets.
				</>
			);
		} else if (val.minimum_tickets == 0 && val.coupon_type == 2) {
			return (
				<>
					You avail minimum of {val.discount}% discount for purchasing upto{" "}
					{val.maximum_tickets} tickets.
				</>
			);
		} else if (val.minimum_tickets != 0 && val.coupon_type == 1) {
			return (
				<>
					You avail minimum of{" "}
					<CurrencyWithAmount
						fontSize={"20px"}
						currencyName={val?.currency_name}
						number={val?.discount}
					/>{" "}
					discount for purchasing between {val.minimum_tickets} and {val.maximum_tickets}{" "}
					tickets.
				</>
			);
		} else if (val.minimum_tickets != 0 && val.coupon_type == 2) {
			return (
				<>
					You avail minimum of {val.discount}% discount for purchasing between{" "}
					{val.minimum_tickets} and {val.maximum_tickets} tickets.
				</>
			);
		} else if (val.coupon_type == 1) {
			return (
				<>
					You can avail minimum of{" "}
					<CurrencyWithAmount
						fontSize={"20px"}
						currencyName={val?.currency_name}
						number={val?.discount}
					/>{" "}
					discount for purchasing {val.minimum_tickets} tickets at least.
				</>
			);
		} else if (val.coupon_type == 2) {
			return (
				<>
					You can avail minimum of {val.discount}% discount for purchasing{" "}
					{val.minimum_tickets} tickets at least.
				</>
			);
		}
	}
}

export const categorizeTickets = tickets => {
	const uncategorized = [];
	let categorized = {};
	tickets.forEach(ticket => {
		if (!ticket.category) uncategorized.push(ticket);
		if (ticket.category) {
			if (!categorized[ticket.category.category_id])
				categorized[ticket.category.category_id] = {
					category_name: ticket.category.category_name,
					category_description: ticket.category.category_description,
					category_id: ticket.category.category_id,
					category_order: ticket.category.category_order,
					is_expanded: ticket.category.is_expanded,
					tickets: []
				};
			categorized[ticket.category.category_id].tickets.push(ticket);
		}
	});
	categorized = Object.values(categorized);
	categorized.sort((a, b) => a.category_order - b.category_order);
	return { uncategorized, categorized };
};

export function checkPotentialDomainTypo(email) {
	// Extract the domain after "@"
	const domain = email?.split("@")[1]?.toLowerCase();

	// Define a list of common typos (modify this list as needed)
	const commonTypos = [
		"gmal.com",
		"gnail.com",
		"gamal.com",
		"gmal.com",
		"gamil.com",
		"gaml.com",
		"gmail.in",
		"gmail.co",
		"gmail.in",
		"gemail.com",
		"hotmal.com",
		"yaho.com"
	];
	// Check if the domain exists in the list
	if (domain) {
		return commonTypos.includes(domain) ? true : false;
	}

	// No match found or invalid email format
	return true;
}

export const checkTeamBasedRegStatus = tickets => {
	let teamRegstatus = 0; // 0 -> No team based tickets, 1 -> Only team based tickets, 2 -> team based and individual tickets
	const teamBasedTickets = tickets.filter(ticket => ticket.team_registration === true);
	if (!_.isEmpty(teamBasedTickets)) {
		if (teamBasedTickets.length === tickets.length) teamRegstatus = 1;
		else teamRegstatus = 2;
	} else {
		teamRegstatus = 0;
	}

	return teamRegstatus;
};

export const regexMethods = {
	//This is used for certaing regex operation
	allowOnlyPositiveNumber: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/[0-9]/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	allowAlphaNumeric: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/^[a-z0-9-]+/i.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	allowAlphaNumericAndSpecial: evt => {
		//alphanumeric and accpet special characters :- @, &, #, $, !, (, ), *
		const charCode = String.fromCharCode(evt.which);
		if (!/^[a-zA-Z0-9@&#$!()*-]+$/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	noSpaces: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/^\S*$/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	}
};
