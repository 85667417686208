import { baseUrl } from "./config";

const awsconfig = {
	"aws_project_region": process.env.AWS_PROJECT_REGION ? process.env.AWS_PROJECT_REGION : "ap-southeast-1",
	"aws_cognito_identity_pool_id": process.env.AWS_COGNITO_IDENTITY_POOL_ID ? process.env.AWS_COGNITO_IDENTITY_POOL_ID : "ap-southeast-1:9a5331da-329a-497d-be61-15668b42bf8f",
	"aws_cognito_region": process.env.AWS_COGNITO_REGION ? process.env.AWS_COGNITO_REGION : "ap-southeast-1",
	"aws_user_pools_id": process.env.AWS_USER_POOLS_ID ? process.env.AWS_USER_POOLS_ID : "ap-southeast-1_f6tfrmIsS",
	"aws_user_pools_web_client_id": process.env.AWS_USER_POOLS_WEB_CLIENT_ID ? process.env.AWS_USER_POOLS_WEB_CLIENT_ID : "1cum79568pe0u434ek1d5kin9a",

	"oauth": {
		"domain": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_OAUTH_DOMAIN ? process.env.REACT_APP_OAUTH_DOMAIN : "dev-auth.konfhub.com") : "dev-auth.konfhub.com",
		"redirectSignIn": baseUrl,
		"scope": ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
		"redirectSignOut": baseUrl,
		"responseType": 'code'
	},

	"cookieStorage": {
		"domain": process.env.REACT_APP_HOST_NAME ? process.env.REACT_APP_HOST_NAME : 'localhost',
		"secure": true,
		"path": '/',
		"expires": 365
	}
};

export default awsconfig;
